import { Inject, Injectable } from '@angular/core';
import { ProfileImageDTO } from '@noventi/gp-platform/care-providers';

@Injectable({
	providedIn: 'root'
})
export class MediaService {

	constructor(
		@Inject('ENVIRONMENT') private _environment: any
	) {
	}

	public getImage(image: ProfileImageDTO, type: string = 'thumbnail'): string {
		const imageType = type ? type : 'thumbnail';
		return !!image && !!image[imageType] ?
			`${this._environment.endpoints.images}/${image[imageType]}`:
			undefined;
	}

	public dataURItoBlob(dataURI) {
		// convert base64/URLEncoded data component to raw binary data held in a string
		let byteString;
		if (dataURI.split(',')[0].indexOf('base64') >= 0)
			byteString = atob(dataURI.split(',')[1]);
		else
			byteString = unescape(dataURI.split(',')[1]);
		// separate out the mime component
		const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
		// write the bytes of the string to a typed array
		const ia = new Uint8Array(byteString.length);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		return new Blob([ia], {type:mimeString});
	}

	public downloadBlob(blob: Blob, filename: string, extension = '.pdf'): void {
    const data = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = data;
    link.download = filename + extension;
    link.click();
    setTimeout(() => {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
    }, 100);
  }
}
